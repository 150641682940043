import LazyLoad from "vanilla-lazyload";

const createLazyLoadInstance = (elements) => {
  let images = document.querySelectorAll("img, source, iframe, video, .lazy");

	return new LazyLoad({
    unobserve_entered: true,
    webp: true,
    elements_selector: ".lazy"
  }, 
  images
  );
};

const delayedCreateLazyLoadInstance = () => {
  setTimeout(createLazyLoadInstance, 100);
}

document.addEventListener("DOMContentLoaded", createLazyLoadInstance);

window.lazyload = () => {
  console.log( 'doing lazyload' );
  createLazyLoadInstance();
}