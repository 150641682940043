import {
  onVisibilityChange
} from './helpers'

global.jQuery = require('jquery');
window.jQuery = require('jquery');
window.acf = {};
global.acf = {};

import './lazyload'
import './cookieconsent'
import './popups'
import 'alpinejs/dist/cdn.min.js';
import '@alpinejs/intersect/dist/cdn.min.js';


const elements = [
  '#trainingslist',
  '#partnerlogos',
  '#partnerlogos__ourstory',
  '#testimonials',
  '#td__filter',
  '.tdproducts',
  '.archive-product',
  '#trajecttimeline',
  '#voordelenpartner',

  '#typewriting',
  '[data-bs-toggle="tooltip"]',
  '#search_form',
  '.tabs__tabs__nav',
  '.mainprod__tabs__wrapper',
  '.checkout',
  '.cornerstone-tabs',
  '.singlepartner__stap'
];

[].forEach.call(elements, (element) => {


  if (document.querySelector(element) !== null && document.querySelector(element) !== undefined) {

    const lazyloadHandler = onVisibilityChange(document.querySelector(element), function () {


      /**
       * Old
       */
     
      if (element === '#typewriting') {
        const TypeWriter = import('./typewriter')
      }
      // if (element === '[data-bs-toggle="tooltip"]') {
      //   const Tooltips = import('./tooltips')
      // }
      if (element === '#search_form') {
        const Search = import('./search')
      }
      if (element === '.tabs__tabs__nav') {
        const Tabs = import('./tabs')
      }
      
      if (element === '.mainprod__tabs__wrapper') {
        const Product = import('./product')
      }

      if (element === '.checkout') {
        const Vue = import('./Vue/main')
      }

      if (element === '.cornerstone-tabs') {
        const CornerstoneTabs = import('./cornerstone-tabs')
      }

      // if (element === '.singlepartner__stap') {
      //   const StapBudget = import('./stap-budget.js');
      // }

      /**
       * New
       */
       if (element === '.archive-product') {
        const Filters = import('./filters')
      }
      if (element === '#trainingslist') {
        const TrainingsListSwiper = import('./trainingslist');
      }

      if (element === '#partnerlogos') {
        const Logos = import('./logos')
      }

      if (element === '#trajecttimeline') {
        const Logos = import('./timeline')
      }

      if (element === '#voordelenpartner') {
        const Logos = import('./voordelen')
      }

      if (element === '#partnerlogos__ourstory') {
        const Logos = import('./logos-ourstory')
      }

      if (element === '#testimonials') {
        const Testimonials = import('./testimonials')
      }

    

    })

    if (window.addEventListener) {
      addEventListener('DOMContentLoaded', lazyloadHandler, false);
      addEventListener('load', lazyloadHandler, false);
      addEventListener('scroll', lazyloadHandler, false);
      addEventListener('resize', lazyloadHandler, false);
    } else if (window.attachEvent) {
      attachEvent('onDOMContentLoaded', lazyloadHandler); // Internet Explorer 9+ :(
      attachEvent('onload', lazyloadHandler);
      attachEvent('onscroll', lazyloadHandler);
      attachEvent('onresize', lazyloadHandler);
    }
  }
});